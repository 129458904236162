import "./App.css";
import words from "./words.json";
import niceColorPalettes from "nice-color-palettes";
import isDarkColor from "is-dark-color";

const FIVE_MINUTES = 1000 * 60 * 5;

let word = window.localStorage["word"];
const at = parseInt(window.localStorage["at"]);
let colorIdx = parseInt(window.localStorage["cidx"]);

function getColor() {
  return (window.localStorage["cidx"] = Math.floor(
    Math.random() * niceColorPalettes.length
  ));
}

if (!colorIdx) {
  colorIdx = getColor();
}

if (!word || !at || Date.now() - at > FIVE_MINUTES) {
  word = words[Math.floor(Math.random() * words.length)];
  window.localStorage["word"] = word;
  window.localStorage["at"] = Date.now();
  colorIdx = getColor();
}

const color = niceColorPalettes[colorIdx][0];

function App() {
  return (
    <div
      className="App"
      style={{ background: color, color: isDarkColor(color) ? "#fff" : "#000" }}
    >
      <h1>You're Probably</h1>
      <div>{word}</div>
    </div>
  );
}

export default App;
